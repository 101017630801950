<template>
  <div class="box content_area">
    <div class="title">
      合作伙伴
    </div>
    <div class="content">
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon8.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon9.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon10.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon11.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon8.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon9.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon10.png" alt="">
      </div>
      <div class="img-wrap">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon11.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "risks",
  data() {
    return {
      leftList: [],
      leftLoading: false,
      rightList: [],
      rightLoading: false,
      rightCount: 0
    }
  },
  mounted() {
    this.getLeftList()
    this.getRightList()
  },
  methods: {
    async getLeftList() {
      this.leftLoading = true
      let parmas = {
        start: 0,
        limit: 4,
        source: this.PJSource
      }
      let res = await this.$store.dispatch("API_index/getHomeWarning", parmas);
      this.leftLoading = false
      if (res.success) {
        this.leftList = res.data
      }
    },
    async getRightList() {
      this.rightLoading = true
      let parmas = {
        source: this.PJSource,
        limit: 4,
        is_show: 1,
        start: 0,
        language: this.LOCALE === "en" ? 1 : 2,
      }
      let res = await this.$store.dispatch("API_index/downloadList", parmas);
      this.rightLoading = false
      if (res.success) {
        this.rightList = res.data
        this.rightCount = res.total
      }
    },
    handleLeftItem(item) {
      this.$router.push({
        path: "/warningList",
        query: {
          company_name: item.company_name
        },
      });
    },
    handleRightItem(item) {
      window.open(item.url)
    },
    handleLeftMore() {
      this.$router.push('/warningList')
    },
    handleRightMore() {
      this.$router.push('/downloadSection')
    },
  }
}
</script>

<style scoped lang="less">
.box {
  margin-top: 80px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #2D8BCE;
    border-bottom: 1px solid #DDD;
    padding-bottom: 16px;
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 78px;
      height: 2px;
      background: #2D8BCE;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    .img-wrap {
      margin-right: 18px;
      margin-bottom: 20px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      & > img {
        width: 282px;
        height: 101px;
      }
    }
  }
}
</style>