<template>
  <div class="container">
    <carouselBanner paddingTop="24%" :bannerType="1"/>
    <activity/>
    <div class="content_area">
      <el-image
          style="width: 1200px; height: 114px;margin-top: 40px;"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon4.png"
      ></el-image>
    </div>
    <schoolCooperation/>
    <education/>
    <risks></risks>
<!--    <cooperation/>-->
  </div>
</template>

<script>
import carouselBanner from '@/baseComponents/carouselBanner'
import activity from '~dlc/components/index/home/activity'
import schoolCooperation from '~dlc/components/index/home/schoolCooperation'
import education from '~dlc/components/index/home/education'
import risks from '~dlc/components/index/home/risks'
import cooperation from '~dlc/components/index/home/cooperation'
import baseShippingDate from "~/baseComponents/baseShippingDate/components/search"

export default {
  metaInfo: {
    title: '首页'
  },
  components: {
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    risks,
    cooperation,
    baseShippingDate
  },
  data() {
    return {};
  },
  computed: {},
  created() {
  },
  methods: {
    goApply() {
      this.$router.push('/memberShip')
    },
    handleShipSearch(val, val2) {
      if (val.date) val.date = this.$moment(val.date).unix()
      this.$router.push({
        path: '/baseShippingDate',
        query: {
          polCode: val.polCode,
          podCode: val.podCode,
          date: val.date,
          polName: val2.polName,
          podName: val2.podName
        }
      })
    },
  },
};
</script>
<style scoped lang="less">
.container {
  background: #FFFFFF;

  .apply {
    margin-top: 30px;
    width: 100%;
    height: 120px;
    background: #D8D8D8;
    line-height: 120px;
    color: #909399;
    font-size: 40px;
    text-align: center;
    cursor: pointer;
  }

  .education {
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBack.png");
    height: 480px;
    background-size: 100% 100%;
  }

  .cooperation {
    margin-top: 38px;
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBg2.png");
    height: 215px;
    background-size: 100% 100%;
  }

  .pointer {
    cursor: pointer;
  }
}
</style>
