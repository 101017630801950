<template>
  <div class="box content_area">
    <div class="left">
      <div class="title">
        公示公告
      </div>
      <div class="content">
        <noDataImg class="nodata" v-if="!leftLoading && leftList.length === 0"></noDataImg>
        <div class="item cursor" v-for="(item,index) in leftList" :key="index" @click="goDetail(item)">
          <div class="text">
            {{ item.title }}
          </div>
          <div class="time">
            {{ item.created_time|secondFormat('LL') }}
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="title">
        政策法规
      </div>
      <div class="content">
        <noDataImg class="nodata" v-if="!rightLoading && rightList.length === 0"></noDataImg>
        <div class="item cursor" v-for="(item,index) in rightList" :key="index" @click="goDetail(item)">
          <div class="text">
            {{ item.title }}
          </div>
          <div class="time">
            {{ item.created_time|secondFormat('LL') }}
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-item">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon1.png" alt="">
        <div>
          星级仓库评定
        </div>
      </div>
      <div class="right-item">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon2.png" alt="">
        <div>
          仓储企业评定
        </div>
      </div>
      <div class="right-item">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon3.png" alt="">
        <div>
          信用等级评定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import advertising from "~dlc/components/index/home/advertising";

export default {
  name: "activity",
  components: {advertising},
  data() {
    return {
      leftList: [],
      leftLoading: false,
      rightList: [],
      rightLoading: false,
      advertising: {},
    };
  },
  created() {
    this.getLeftList();
    this.getRightList();
  },
  methods: {
    async getLeftList() {
      let params = {
        content_type: 88,
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
    async getRightList() {
      let params = {
        content_type: 4,
        source: this.PJSource,
        nopage: 0,
        limit: 4,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.rightLoading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.rightLoading = false;
      if (res.success) {
        this.rightList = res.data;
      }
    },

    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {id: item.id},
      });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  display: flex;
  margin-top: 20px;

  .title {
    width: 100%;
    padding-bottom: 18px;
    border-bottom: 1px solid #DDDDDD;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #2D8BCE;

    &::after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 74px;
      height: 2px;
      background: #2D8BCE;
    }
  }

  .content {
    padding-top: 24px;
    position: relative;

    .nodata {
      top: 100px;
    }

    .item {
      width: 100%;
      display: flex;
      margin-bottom: 32px;
      padding-left: 15px;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        left: 0;
        top: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #000;
      }

      .text {
        width: 220px;
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-right: 36px;
      }

      .time {
        width: 70px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .left {
    width: 336px;
    margin-right: 60px;
  }

  .middle {
    width: 336px;
    margin-right: 55px;
  }

  .right {
    .right-item {
      width: 411px;
      height: 97px;
      background-color: #E2F3FF;
      padding-left: 60px;
      display: flex;
      align-items: center;

      img {
        width: 46px;
        height: 44px;
        margin-right: 16px;
      }

      & > div {
        font-size: 18px;
        font-weight: 400;
        color: #2D8BCE;
      }
    }
  }
}
</style>