<template>
  <div class="box">
    <div class="wrap content_area">
      <div class="title">
        协会会员
      </div>
      <div class="content">
        <el-carousel indicator-position="outside" arrow="never" height="200px">
          <el-carousel-item v-for="item in 1" :key="item">
            <div class="item">
              <div class="item-content">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon6.png" alt="">
                <div class="text">
                  <div class="name">
                    李云杰
                  </div>
                  <div class="desc">
                    在这个全世界在秀恩爱的日子，「微信」上线了情人节限定状态，让你快速给今天的自己贴个标签。在特殊节日上线限定内容，能够大幅度提高用户对这个功能的使用率，是促活的好方式。有趣的是，在三个状态标签里，除了传统的吃狗粮、撒狗粮，还有一个「疲惫」，脱离了传统标签更突出。微信」上线了情人节...
                  </div>
                </div>
              </div>
              <div class="item-content">
                <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon7.png" alt="">
                <div class="text">
                  <div class="name">
                    粱鹏鹏
                  </div>
                  <div class="desc">
                    在这个全世界在秀恩爱的日子，「微信」上线了情人节限定状态，让你快速给今天的自己贴个标签。在特殊节日上线限定内容，能够大幅度提高用户对这个功能的使用率，是促活的好方式。有趣的是，在三个状态标签里，除了传统的吃狗粮、撒狗粮，还有一个「疲惫」，脱离了传统标签更突出。微信」上线了情人节限定状态，...
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import videoPlay from '@/baseComponents/videoPlay'

export default {
  name: "education",
  components: {
    videoPlay
  },
  data() {
    return {
      dataList: [],
      loading: false
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      let params = {
        source: this.PJSource,
        start: 0,
        limit: 3,
        home_flag: 1
      }
      let res = await this.$store.dispatch('API_company/association_video_List', params)
      this.loading = false
      if (res.success) {
        console.log(res);
        this.dataList = res.data
      }
    },
    handleMore() {
      this.$router.push('/college')
    },
    handleContent(item) {
      if (this.IS_LOGIN) {
        this.$router.push({
          path: "/college_detail",
          query: {
            parameter: this._encode({
              video_id: item.id,
            }),
          },
        })
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$router.push({
              path: "/college_detail",
              query: {
                parameter: this._encode({
                  video_id: item.id,
                }),
              },
            })

          },
        });
      }


    }
  }
}
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 390px;
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/dlc/home/icon5.png") no-repeat 0 0;
  background-size: 100%;

  .wrap {
    padding-top: 46px;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
    }

    .content {
      padding-top: 52px;

      /deep/ .el-carousel__indicator--horizontal {
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        opacity: 0.5;
        border-radius: 5px;
        padding: 0;
        margin-right: 5px;
      }

      /deep/ .el-carousel__indicator--horizontal.is-active {
        opacity: 1;
      }

      /deep/ .el-carousel__button {
        display: none;
      }

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-content {
          display: flex;

          & > img {
            width: 144px;
            height: 144px;
            border-radius: 8px;
          }

          .text {
            padding-left: 16px;
            padding-top: 4px;

            .name {
              font-size: 18px;
              font-weight: bold;
              color: #FFFFFF;
            }

            .desc {
              width: 400px;
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22px;
              display: -webkit-box;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
            }
          }
        }
      }
    }
  }
}
</style>
