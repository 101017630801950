<template>
  <div class="box content_area">
    <div class="left">
      <div class="title">
        协会动态
      </div>
      <div class="content">
        <noDataImg class="nodata" v-if="!leftLoading && leftList.length === 0"></noDataImg>
      <div class="item cursor" v-for="(item,index) in leftList" :key="index" @click="goDetail(item)">
          <div class="text">
            {{item.title}}
          </div>
          <div class="time">
            {{ item.created_time|secondFormat('LL') }}
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">
        行业动态
      </div>
      <div class="content">
        <noDataImg class="nodata" v-if="!rightLoading && rightList.length === 0"></noDataImg>
      <div class="item cursor" v-for="(item,index) in rightList" :key="index" @click="goDetail(item)">
          <div class="text">
            {{item.title}}
          </div>
          <div class="time">
            {{ item.created_time|secondFormat('LL') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCooperation",
  props: {
    advertising3: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      leftList: [],
      centerList: [],
      rightList: [],
      leftLoading: false,
      centerLoading: false,
      rightLoading: false
    }
  },
  mounted() {
    this.getLeftList();
    this.getRightList();
  },
  methods: {
    async getLeftList() {
      let params = {
        content_type: 2,
        source: this.PJSource,
        nopage: 0,
        limit: 6,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
    async getRightList() {
      let params = {
        content_type: 3,
        source: this.PJSource,
        nopage: 0,
        limit: 6,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.rightLoading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.rightLoading = false;
      if (res.success) {
        this.rightList = res.data;
      }
    },

    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {id: item.id},
      });
    },
  }
}
</script>

<style scoped lang="less">
.box {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #2D8BCE;
    padding-bottom: 16px;
    border-bottom: 1px solid #DDDDDD;
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 74px;
      height: 2px;
      background: #2D8BCE;
    }
  }

  .item {
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    position: relative;

    &::after {
      position: absolute;
      content: " ";
      left: 0;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #000;
    }

    .text {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }

  .left, .right {
    width: 570px;

    .content {
      margin-top: 25px;
      position: relative;
      min-height: 295px;
      .nodata{
        top:50px;
      }
    }
  }
}
</style>
